import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";
import { ContentProductCarouselComponent } from "@/components/common/ContentProductCarousel/ContentProductCarousel";
import { Description, Heading, Icon, Wrapper } from "./ErrorPage.styled";

export const ErrorPageContent = () => {
  const { t } = useTranslations();

  return (
    <>
      <SiteContainer>
        <Wrapper>
          <Heading>{t("errors.not.found.title")}</Heading>
          <Icon />
          <Description>{t("errors.not.found.subtitle")}</Description>
        </Wrapper>
      </SiteContainer>
      <ContentProductCarouselComponent
        strategy="personalized"
        title={t("placement.personalized-product-list.h1")}
      />
    </>
  );
};
