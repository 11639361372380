import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { typographyToCss, MQ } from "react-app/src/styles/helpers";
import { MultiSports } from "@xxl/icons";

const typography = {
  heading: {
    fontSize: 26,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 34,
    },
  },
};

const { heading } = typography;

const { spaces } = xxlTheme;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spaces.regular};
  padding: ${spaces.large} 0;

  ${MQ("laptop")} {
    gap: ${spaces.big};
    padding: ${spaces.big} 0;
  }
`;

export const Heading = styled.h1`
  margin: 0;
  ${typographyToCss(heading)};
  text-align: center;
`;

export const Icon = styled(MultiSports)`
  width: 200px;
  height: 130px;

  ${MQ("laptop")} {
    width: 300px;
    height: 195px;
  }
`;

export const Description = styled.p`
  margin: 0;
`;
